import styled from 'styled-components'
import media from '../utils/breakpoints'

export const CareerWrapper = styled.section`
  padding: 2rem 0;
  ${media.md} {
    padding: 3rem 0;
  }
`
export const CareerInner = styled.div``
export const CareerOpening = styled.div``

export const OpenPositionsWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  div.position {
    padding: 0.5rem;
    border: #cdc1ff solid 2px;
    border-radius: 8px;
    background: var(--cool-white);
    transition: background-color 500ms ease, color 500ms ease;
    h3 {
      padding-bottom: 0.5rem;
      transition: color 300ms ease;
    }
    p {
      color: var(--black);
    }

    &:hover {
      h3 {
        color: var(--blue);
      }
      background: #fefefe;
    }
  }

  ${media.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const OpenPositionsEmpty = styled.div`
  padding: 2rem;
  text-align: center;
  border: 2px var(--grey) solid;
  border-radius: 10px;
  background-color: rgb(239, 239, 239);
  font-size: 1.2rem;
`
