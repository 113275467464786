import React from 'react'
import SecondaryHeader from '../components/header/secondary/secondary-header'
import CareersPage from '../components/careers/careers'
import SEO from '../components/seo/seo'
function Careers() {
  const title = 'Careers at Blue Geek Hosting'
  const subtitle =
    'If you can feel the excitement and wonder, you should probably just join us!'

  return (
    <>
      <SEO title="Careers" />
      <SecondaryHeader title={title} subtitle={subtitle} />
      <CareersPage />
    </>
  )
}

export default Careers
