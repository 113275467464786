const openPositions = [
  {
    title: 'Sr. Backend Developer',
    desc:
      'Lorem Ipsum Somthng great about this Job, Lorem Ipsum Somthng great about this Job',
    url: '#'
  },
  {
    title: 'Full-stack engineer',
    desc:
      'Lorem Ipsum Somthng great about this Job, Lorem Ipsum Somthng great about this Job',
    url: '#'
  },
  {
    title: 'Sr. Backend Engineer',
    desc:
      'Lorem Ipsum Somthng great about this Job, Lorem Ipsum Somthng great about this Job',
    url: '#'
  }
]

export default openPositions
