import React from 'react'
import { OpenPositionsWrapper, OpenPositionsEmpty } from './careers-styles'
import { Container } from '../common/common-styles'
import openPositions from '../../content/careers/open-positions'

function OpenPositions() {
  const isEmpty = !openPositions[0]

  if (isEmpty)
    return (
      <Container>
        <OpenPositionsEmpty>
          Sorry, currently no open positions.
        </OpenPositionsEmpty>
      </Container>
    )
  return (
    <Container>
      <OpenPositionsWrapper>
        {openPositions.map(({ title, desc, url }) => (
          <a href={url}>
            <div className="position">
              <h3>{title}</h3>
              <p>{desc}</p>
            </div>
          </a>
        ))}
      </OpenPositionsWrapper>
    </Container>
  )
}

export default OpenPositions
