import React from 'react'
import { CareerWrapper, CareerInner } from './careers-styles'
import { Container, TwoColumnWrapper } from '../common/common-styles'
import PrimaryHeading from '../heading/primary-heading'
import careersSVG from '../../images/features/careers.svg'
import OpenPositions from './open-positions'

function CareersPage() {
  const title = 'Open Positions'
  const subtitle =
    'We give our clients a better place to live and work in. And we build the teams to drive these aspirations further.'

  return (
    <CareerWrapper>
      <Container>
        <CareerInner>
          <TwoColumnWrapper>
            <div className="image">
              <img src={careersSVG} alt="job profiles illustration" />
            </div>
            <div className="content">
              <h3>Our jam</h3>
              <p>
                We don't content ourselves with the products our clients want;
                we develop the products we know they need.
              </p>
              <h3>Work where you want</h3>
              <p>
                Just let us know in time, make sure you have a stable internet
                connection, and you are good to go.
              </p>
              <h3>Work when you want</h3>
              <p>
                It's up to them to work efficiently with colleagues, so you have
                enough time online together.
              </p>
            </div>
          </TwoColumnWrapper>

          <PrimaryHeading title={title} subtitle={subtitle} />
          <OpenPositions />
        </CareerInner>
      </Container>
    </CareerWrapper>
  )
}

export default CareersPage
